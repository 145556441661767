/* Font */
@font-face {
    font-family: PoppinsLight;
    src: url(../fonts/Poppins-Light.ttf);
}

@font-face {
    font-family: PoppinsRegular;
    src: url(../fonts/Poppins-Regular.ttf);
}

@font-face {
    font-family: PoppinsMedium;
    src: url(../fonts/Poppins-Medium.ttf);
}

@font-face {
    font-family: PoppinsItalic;
    src: url(../fonts/Poppins-Italic.ttf);
}

@font-face {
    font-family: PoppinsBoldItalic;
    src: url(../fonts/Poppins-BoldItalic.ttf);
}

@font-face {
    font-family: PoppinsSemiBold;
    src: url(../fonts/Poppins-SemiBold.ttf);
}

@font-face {
    font-family: PoppinsBold;
    src: url(../fonts/Poppins-Bold.ttf);
}

@font-face {
    font-family: PoppinsExtraBold;
    src: url(../fonts/Poppins-ExtraBold.ttf);
}

@font-face {
    font-family: PoppinsBlack;
    src: url(../fonts/Poppins-Black.ttf);
}

/* Bootstrap */
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

$font: "PoppinsRegular";
$fontL: "PoppinsLight";
$fontI: "PoppinsItalic";
$fontM: "PoppinsMedium";
$fontSm: "PoppinsSemiBold";
$fontB: "PoppinsBold";
$fontEB: "PoppinsExtraBold";
$fontBi: "PoppinsBoldItalic";
$fontBl: "PoppinsBlack";

$yellow: #fffa00;
$purple: #8400ff;
$gray: #666666;

@import "./sidebar";

// General
body {
    font-family: $font;
}

p {
    font-size: 14px;
}
p.help-text {
    font-size: 10px;
    font-family: $fontL;
    line-height: 14px;
    color: #999999;
    text-align: justify;

    & a {
        font-family: $fontB;
    }
}
a {
    text-decoration: none;
    color: inherit;

    &:hover {
        color: #ffa800;
    }

    &.menu {
        &:hover {
            color: $purple;
            transition: 0.7s;
        }
    }
}

.color-white {
    color: #fff;
}
.color-yellow {
    color: $yellow;
}
.color-yellow-dark {
    color: #ffc700;
}
.color-purple {
    color: $purple;
}
.color-gray {
    color: $gray;
}
.color-light-gray {
    color: #999999;
}
.color-dark-gray {
    color: #56515b;
}
.bg-color-black {
    background-color: #000;
}
.bg-color-yellow {
    background-color: $yellow;
}
.bg-color-purple {
    background-color: $purple;
}
.bg-color-gray {
    background-color: $gray;
}
.bg-color-light-gray {
    background-color: #dedede;
}
.bg-color-primary {
    background: rgba(20, 20, 20, 1);
}
.c-pointer {
    cursor: pointer;
}

.font-light {
    font-family: $fontL;
}
.font-italic {
    font-family: $fontI;
}
.font-bold-italic {
    font-family: $fontBi;
}
.font-medium {
    font-family: $fontM;
}
.font-semi-bold {
    font-family: $fontSm;
}
.font-bold {
    font-family: $fontB;
}
.font-extra-bold {
    font-family: $fontEB;
}
.font-black {
    font-family: $fontBl;
}

.padding-container {
    padding: 8rem 0rem;
}
.padding-card {
    padding: 4rem 3.5rem;
}
.padding-card-second {
    padding: 2rem 3rem 1rem 3rem;

    & h6 {
        color: #666666;

        &:hover {
            color: #ffa800;
            cursor: pointer;
        }
    }
}

.card-form {
    box-shadow: 0 0 35px 10px rgba(0, 0, 0, 0.7);
    border: 0px;
}

.input-search {
    background-color: transparent;
    border: 1px #dfdfdf solid;
    border-radius: 0px;
    padding: 0.75rem 1rem;
    font-size: 16px;
    font-family: $font;
    color: #000;

    &::placeholder {
        font-family: $fontL;
        font-size: 14px;
        color: #999999;
    }
}

form {
    & input,
    & select {
        &.form-control {
            background-color: transparent;
            border: 1px #dfdfdf solid;
            border-radius: 0px;
            padding: 0.75rem 1rem;
            font-size: 16px;
            font-family: $font;
            color: #000;

            &::placeholder {
                font-family: $fontL;
                font-size: 14px;
                color: #999999;
            }
        }
    }

    .form-check-input[type="checkbox"] {
        border-radius: 0px;
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
        border: 1px #a5a5a5 solid;
    }

    & .small-info:hover {
        cursor: pointer;
        transition: 0.6s;
        color: #ffa800;
    }

    & .btn-primary {
        font-size: 12px;
        line-height: 50px;
        font-weight: 600;
        float: right;
        margin-top: 10px;
        padding: 0rem 2rem;
        background-color: #ffc700;
        border: 1px solid #ffc700;
        color: #000;
        border-radius: 0px;
        background-image: linear-gradient(45deg, #ffa800 50%, transparent 50%);
        background-position: 100%;
        background-size: 400%;
        transition: background 250ms ease-in-out;
        transition: 0.3s;

        &.signup {
            background-color: #000;
            color: white;
            border: 1px solid #000;
        }

        &:hover {
            border: 1px solid #ffa800;
            background-position: 0;
            background-color: #ffa800;
            transition: 0.3s;
            color: #000;
        }
    }
}

// Header
nav img {
    max-width: 250px;
    margin: 10px 0px;
    transition: 0.1s;
}

nav .navbar-collapse {
    font-size: 13px;

    & .nav-item {
        margin: 0px 10px;

        & a {
            font-size: 13px;
            color: #ffffff;
            line-height: 50px;
            font-weight: 500;
            cursor: pointer;
            transition: 0.2s;
            vertical-align: baseline;
            text-decoration: none;

            &:hover {
                color: #ffa800;
            }
        }
    }
}

.custom-btn {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    border: 1px solid #ffc700;
    border-radius: 0px;
    color: #fff;
    background-color: #000;
    background-image: linear-gradient(30deg, #ffa800 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    transition: background 250ms ease-in-out;
    padding-top: 0px;
    padding-bottom: 0px;
    text-transform: uppercase;

    &.btn-app {
        background-color: #ffc700;
        border: 1px solid #ffc700;
        color: #000;
        padding: 0.5rem 1rem;
        
        &.btt-add {
            height: 50px;
            width: 100%;
            display: block;
        }

        &.btt-export {
            height: 50px;
            width: 100%;
            display: block;
            background-color: #8400ff;
            border: 1px solid #8400ff;
            background-image: linear-gradient(30deg, #5702a7 50%, transparent 50%);

            & i {
                margin-top: 5px;
                color: #fff;
                transition: 0.3s;
            }
        }
    }

    & span {
        font-size: 13px;
        line-height: 40px;
        font-weight: 500;
        padding: 0px 25px;
        cursor: pointer;
        transition: 0.1s;
    }

    &:hover {
        border: 1px solid #ffa800;
        color: #000;
        background-position: 0;
        transition: 0.3s;

        &.btt-export {
            & i {
                color: #dedede;
                transition: 0.3s;
            }
        }
    }
}

.btn-group .btn.btn-pagination {
    font-size: 12px;
    color: #000;
    background-color: #ffc700;
    border-color: #ffc700;
    transition: 0.7s;

    &:hover,
    &:active {
        background-color: #ffd438;
        transition: 0.7s;
    }
    &:disabled {
        background-color: #836a0f;
        border-color: #836a0f;
    }
}

.dropdown-toggle::after,
.dropdown-toggle::before {
    display: none !important;
}

.dropdown-toggle.btn {
    i {
        &:hover {
            color: #ffa800;
            transition: 0.7s;
        }
    }
}

.modal-content {
    box-shadow: 0px 0px 8px 7px rgba(0, 0, 0, 0.2);

    & .modal-footer {
        background-color: #dedede;
        border-top: 0px;
    }
}

.card.card-event {
    position: relative;
    overflow: hidden;
    border: 0px;
    box-shadow: 0 0 14px 5px rgba(0, 0, 0, 0.7);
    margin-top: 3rem;

    &.event-primary {
        margin-bottom: 5rem;
    }

    & .container-fluid {
        padding: 0px;
    }

    & .img-div {
        border-bottom: 0px;

        & .img-event-header {
            display: block;
            width: 100%;
            max-height: 30vh;
            height: auto;
            object-fit: cover;
        }
    }
}

// Dashboard
.card-data {
    transition: 0.3s;

    &:hover {
        background-color: #efefef;
        border-radius: 0.375rem !important;
        transition: 0.3s;
    }
}

.card-list-header-sort {
    & i {
        margin-left: 0.75rem;
        cursor: pointer;
    }
}

// Swal

.swal2-confirm.swal2-styled {
    font-size: 12px;
    font-weight: 600;
    float: right;
    font-family: $font;
    margin-top: 10px;
    padding: 0rem 2rem;
    background-color: #ffc700;
    border: 1px solid #ffc700;
    color: #000;
    border-radius: 0px;
    background-image: linear-gradient(45deg, #ffa800 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    transition: background 250ms ease-in-out;
    transition: 0.3s;
    width: 150px;
    height: 40px;

    &:hover {
        border: 1px solid #ffa800;
        background-position: 0;
        transition: 0.3s;
        color: #000;
    }
}
.swal2-cancel.swal2-styled {
    font-size: 12px;
    font-weight: 600;
    float: right;
    font-family: $font;
    margin-top: 10px;
    padding: 0rem 2rem;
    background-color: #dedede;
    border: 1px solid #dedede;
    color: #000;
    border-radius: 0px;
    background-image: linear-gradient(45deg, #ffa800 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    transition: background 250ms ease-in-out;
    transition: 0.3s;
    width: 150px;
    height: 40px;

    &:hover {
        border: 1px solid #ffa800;
        background-position: 0;
        transition: 0.3s;
        color: #000;
    }
}

.swal2-success-ring {
    border: 0.25em solid rgb(132 0 255 / 30%) !important;
}
.swal2-success-line-long,
.swal2-success-line-tip {
    background-color: #8400ff !important;
}

// Swal

.link-card-list {
    transition: 0.7s;
    &:hover {
        color: #ffc700 !important;
        transition: 0.7s;
    }
}

.widget-number-link {
    text-decoration: none;

    .card.widget-number {
        box-shadow: 5px 5px 11px -8px rgb(0 0 0 / 75%);
        background-color: #dedede;
        border: 0px;
        transition: 0.7s;

        h4 {
            color: $purple;
        }
        & i {
            font-size: 50px;
            text-align: center;
            color: #000;
        }

        &:hover {
            background-color: $purple;
            transition: 0.7s;

            i,
            h4,
            h5 {
                color: white;
            }
        }
    }
}

.custom-btn-primary {
    border: 1px solid #333333;
    background-color: #000;
    background-image: linear-gradient(45deg, #ffa800 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    transition: background 250ms ease-in-out;
    color: #fff;
    padding: 6px;
    border-radius: 0px;
    transition: 0.3s;

    & span {
        font-size: 13px;
        padding: 0px 10px;
        font-weight: 500;
        transition: 0.1s;
    }

    &:hover {
        border: 1px solid #ffa800;
        background-color: #ffa800;
        color: #000;
        background-position: 0;
        transition: 0.3s;
    }
}

.btn-call-to-action {
    border: 0px;
    background-color: #ffa800;
    background-image: linear-gradient(45deg, #ffa800 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    transition: background 250ms ease-in-out;
    color: #fff;
    padding: 6px;
    border-radius: 0px;
    transition: 0.3s;
    & span {
        font-size: 13px;
        padding: 0px 10px;
        font-weight: 500;
        transition: 0.1s;
    }

    &:hover {
        border: 0px;
        color: #000;
        background-position: 0;
        transition: 0.3s;
    }

    &.big-button {
        padding: 1rem 2rem;
        font-family: $fontB;

        &.payment-button {
            width: 65%;
        }
    }
}

// Form Login
.box-bg {
    position: relative;
}
.box-bg .quotes {
    display: flex;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.box-bg .active {
    opacity: 1;
}
.box-bg .next {
    opacity: 1;
}
.box-bg .img-bg-01 {
    background: url("~../../assets/img/img_01.jpg") no-repeat center center;
    background-size: cover;
}
.box-bg .img-bg-02 {
    background: url("~../../assets/img/img_02.jpg") no-repeat center center;
    background-size: cover;
}
.box-bg .img-bg-03 {
    background: url("~../../assets/img/img_03.jpg") no-repeat center center;
    background-size: cover;
}
.box-bg .img-bg-04 {
    background: url("~../../assets/img/img_04.jpg") no-repeat center center;
    background-size: cover;
}
.box-bg .img-bg-05 {
    background: url("~../../assets/img/img_05.jpg") no-repeat center center;
    background-size: cover;
}
.box-bg .img-bg-06 {
    background: url("~../../assets/img/img_06.jpg") no-repeat center center;
    background-size: cover;
}
.box-bg .img-bg-07 {
    background: url("~../../assets/img/img_07.jpg") no-repeat center center;
    background-size: cover;
}

// Footer
footer {
    h4 {
        font-size: 14px;
    }
    // Here is an improved version of the 'p' class code:

    p {
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
        margin: 5px 0;
        display: block;

        & a.btn-chegar {
            font-size: 13px;
            color: #ffc700;
            text-decoration: underline;
        }
    }
    & img.logo-footer {
        max-width: 150px;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
            transform: scale(1.03);
            transition: 0.3s;
        }
    }

    & .social-media-icon {
        transition: color 0.3s, transform 0.3s;
        cursor: pointer;
        &:hover {
            color: #ffa800;
            transform: scale(1.1);
        }
    }

    & .copyright {
        padding: 20px 0px;
        float: left;
        display: block;
        border-top: 1px #202020 solid;
    }
}

@media (min-width: 993px) {
    .custom-navbar {
        .navbar-collapse {
            display: flex !important;
            justify-content: flex-end;
        }
    }
}

@media (max-width: 1600px) {
    .card-list-banner-image {
        height: 100px !important;
    }
}

@media (max-width: 992px) {
    .custom-navbar {
        .navbar-collapse {
            justify-content: center;
            & .nav-item {
                display: block;
                margin: 10px 0;
            }
        }
    }

    .custom-btn-primary {
        width: 60%;
    }

    .padding-card {
        padding: 2rem;
    }
    .padding-card-second {
        padding: 2rem;
    }

    .card-data {
        background-color: #ebebeb;
        box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.2);
        border-radius: 8px !important;
        margin-bottom: 1rem !important;
    }

    .btn-primary.signup {
        width: 100%;
    }

    .btn-call-to-action.big-button.payment-button {
        width: 100%;
        padding: 0.5rem;
        margin-top: 2rem;
    }

    // Footer
}

@media (max-width: 350px) {
    .navbar-brand {
        max-width: 70%;
        img.logo-header {
            max-width: 90%;
        }
    }

    .card-list-banner-image {
        height: 100% !important;
    }
}

// Loading

.loading {
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);

    &:before,
    &:after,
    div {
        width: 200px;
        height: 200px;
        top: 50%;
        left: 50%;
        display: block;
        position: absolute;
        border-radius: 50%;
        border: 4px solid $purple;
    }

    &:before {
        content: "";
        animation: scale 1s ease-in infinite;
    }
    &:after {
        content: "";
        animation: scale 2s ease-in infinite;
    }

    div {
        animation: scale 3s ease-in infinite;
    }
}

.loading.open {
    display: block;
    position: fixed;
    top: 0;
    z-index: 99999;
}

@keyframes scale {
    from {
        transform: translate(-50%, -50%) scale(0, 0);
    }
    to {
        transform: translate(-50%, -50%) scale(1, 1);
    }
}
