/* ============================================ */
/* CSS By http://nerdweb.com.br */
/* ============================================ */


/* --== BODY ==-- */
.site {min-height:100vh;height:100%;display:flex;flex-direction:column;}

/* --== CONTENT ==-- */
.site-container {flex:1;display:flex;flex-direction:column;background-color:#000000;}

/* --== FORMS CAMPOS ==-- */
input[type="text"],
input[type="email"],
input[type="url"],
input[type="tel"],
select,
textarea {font-size:16px;}

/* --== PLACEHOLDER ==-- */
::-webkit-input-placeholder {font-size:14px;font-weight:300;color:#757575;}
::-webkit-textarea-placeholder {font-size:14px;font-weight:300;color:#757575;}
:-moz-placeholder {font-size:14px;font-weight:300;color:#757575;}
::-moz-placeholder {font-size:14px;font-weight:300;color:#757575}

.color-link-ativo {color:#FFA800!important;cursor:default!important;}

/* --==============-- */
/* --== modelo BLOCO 01 ==-- */
.modelo-bloco-01 {width:100%;height:auto;float:left;margin-top:133px;padding:0px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(255, 255, 255, 1);position:relative;z-index:10;}
.modelo-bloco-01 .center-content {max-width:1024px;width:100%;min-height:1000px;height:auto;padding:10px 0px 80px 0px;display:flex;flex-direction:column;align-items:center;}

/* --==============-- */
/* --== BLOCO 01 ==-- */
.bloco-01 {width:100%;height:auto;float:left;margin-top:133px;padding:0px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(0, 0, 0, 1);position:relative;z-index:10;}
.bloco-01 .center-content {max-width:1280px;width:100%;height:auto;padding:0px;display:flex;flex-direction:column;justify-content:center;align-items:center;}
.bloco-01 .center-content .box-banner {width:100%;height:auto;float:left;display:block;position:relative;}
.bloco-01 .center-content .box-banner .img-desktop {display:block;}
.bloco-01 .center-content .box-banner .img-mobile {display:none;}
.bloco-01 .center-content .box-banner img {width:100%;height:auto;float:left;z-index:10}

/* --==============-- */
/* --== BLOCO 05 ==-- */
.bloco-05 {width:100%;height:auto;float:left;padding:0px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(20, 20, 20, 1);position:relative;z-index:10;}
.bloco-05 .center-content {max-width:1280px;width:100%;min-height:50px;height:auto;padding:0px 0px 20px 0px;display:flex;flex-direction:column;justify-content:center;align-items:center;}

.bloco-05 .center-content ul {width:100%;height:auto;margin-top:-80px;float:left;display:flex;flex-direction:row;flex-wrap:wrap;justify-content:space-between;padding-left: 0;}
.bloco-05 .center-content ul li {width:calc(33.333% - 20px);height:auto;float:left;display:flex;flex-direction:row;align-items:center;justify-content:center;background-color:#ffffff;}
.bloco-05 .center-content ul li a {width:100%;height:100%;word-break:break-word;padding:40px 30px;float:left;display:block;transform:scale(1);transition:0.3s;}

.bloco-05 .center-content ul .ativo {background-color:#574696;}
.bloco-05 .center-content ul .inativo {background-color:#323232;}

.bloco-05 .center-content ul .ativo:hover {transform:scale(1.03);transition:0.3s;cursor:pointer;}
.bloco-05 .center-content ul .inativo:hover {transform:scale(1);transition:0.3s;cursor:pointer;}

.bloco-05 .center-content ul .ativo a h2 {width:100%;height:auto;font-size:30px;line-height:34px;color:#ffffff;font-weight:800;float:left;display:block;}
.bloco-05 .center-content ul .ativo a h2 span{width:100%;height:auto;font-size:16px;line-height:18px;color:#ffffff;font-weight:400;float:left;display:block;}
.bloco-05 .center-content ul .ativo a .data-validade {width:100%;height:auto;font-size:12px;line-height:14px;color:#ffffff;font-weight:400;margin:10px 0px;float:left;display:block;}
.bloco-05 .center-content ul .ativo a .data-validade span {color:#FFC700;font-weight:800;}
.bloco-05 .center-content ul .ativo a .valor {width:100%;height:auto;font-size:32px;line-height:34px;color:#ffffff;font-weight:800;float:left;display:block;}
.bloco-05 .center-content ul .ativo a .valor span {font-size:16px;line-height:34px;color:#ffffff;font-weight:400;}
.bloco-05 .center-content ul .ativo a .btn-cta {width:100%;height:50px;font-size:12px;line-height:50px;color:#000000;font-weight:600;text-align:center;margin:10px 0px;float:left;display:block;background-color:#FFC700;background-image:linear-gradient(45deg, #FFA800 50%, transparent 50%);background-position:100%;background-size:400%;transition:background 250ms ease-in-out;transition:0.3s;}
.bloco-05 .center-content ul .ativo a .btn-cta:hover {background-color:#FFA800;background-position:0;transition:0.3s;}
.bloco-05 .center-content ul .ativo a .observacao {width:100%;height:auto;font-size:10px;line-height:14px;color:#999999;float:left;display:block;}



.bloco-05 .center-content ul .inativo a h2 {width:100%;height:auto;font-size:30px;line-height:34px;color:#181818;font-weight:800;float:left;display:block;}
.bloco-05 .center-content ul .inativo a h2 span{width:100%;height:auto;font-size:16px;line-height:18px;color:#181818;font-weight:400;float:left;display:block;}
.bloco-05 .center-content ul .inativo a .data-validade {width:100%;height:auto;font-size:12px;line-height:14px;color:#181818;font-weight:400;margin:10px 0px;float:left;display:block;}
.bloco-05 .center-content ul .inativo a .data-validade span {color:#181818;font-weight:800;}
.bloco-05 .center-content ul .inativo a .valor {width:100%;height:auto;font-size:32px;line-height:34px;color:#181818;font-weight:800;float:left;display:block;}
.bloco-05 .center-content ul .inativo a .valor span {font-size:16px;line-height:34px;color:#181818;font-weight:400;}
.bloco-05 .center-content ul .inativo a .btn-cta {width:100%;height:50px;font-size:12px;line-height:50px;color:#323232;font-weight:600;text-align:center;margin:10px 0px;float:left;display:block;background-color:#181818;}
.bloco-05 .center-content ul .inativo a .observacao {width:100%;height:auto;font-size:10px;line-height:14px;color:#181818;float:left;display:block;}


/* --==============-- */
/* --== BLOCO 02 ==-- */
.bloco-02 {width:100%;height:auto;float:left;padding:0px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(20, 20, 20, 1);position:relative;z-index:10;}
.bloco-02 .center-content {max-width:1280px;width:100%;min-height:300px;height:auto;padding:60px 0px 40px 0px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(255, 255, 255, 0.0);}
.bloco-02 .center-content h2 {width:100%;height:auto;font-size:30px;line-height:34px;color:#ffffff;font-weight:900;text-align:center;margin:0px 0px 10px 0px;float:left;display:block;}
.bloco-02 .center-content ul {width:100%;height:auto;margin-top:30px;float:left;display:flex;flex-wrap:wrap;flex-direction:row;justify-content:center;padding-left: 0;}
.bloco-02 .center-content ul li {max-width:25%;width:100%;height:auto;float:left;margin-bottom:30px;padding:0px 15px;display:flex;flex-direction:column;align-items:center;}
.bloco-02 .center-content ul li .icon {width:60px;height:auto;font-size:34px;line-height:60px;color:#FFA800;font-weight:600;text-align:center;float:left;display:block;}
.bloco-02 .center-content ul li h3 {width:100%;height:auto;font-size:16px;line-height:20px;color:#ffffff;font-weight:600;text-align:center;margin-bottom:2px;float:left;display:block;}
.bloco-02 .center-content ul li p {width:100%;height:auto;font-size:14px;line-height:20px;color:#cccccc;text-align:center;font-weight:300;margin:5px 0px 10px 0px;float:left;display:block;}
.bloco-02 .center-content .btn-cta {max-width:250px;width:100%;height:auto;float:left;}
.bloco-02 .center-content .btn-cta a {width:100%;height:auto;font-size:14px;line-height:50px;color:#000000;font-weight:600;text-align:center;float:left;border:2px #000000 solid;border-radius:50px;}
.bloco-02 .center-content .btn-cta a:hover {color:#ffffff;background-color:#000000;border:2px #000000 solid;}

/* --==============-- */
/* --== BLOCO 03 ==-- */
.bloco-03 {width:100%;height:auto;float:left;padding:80px 40px 40px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(0, 0, 0, 1);position:relative;z-index:10;}
.bloco-03 .center-content {max-width:1280px;width:100%;min-height:300px;height:auto;padding:0px 0px 0px 0px;display:flex;flex-direction:row;flex-wrap:wrap;justify-content:space-between;background:rgba(255, 255, 255, 0.0);}
.bloco-03 .center-content .positivo-fotos {width:calc(50% - 25px);height:auto;float:left;display:flex;flex-direction:column;}

/** CUSTOM CSS OWL CAROUSEL  **/
.bloco-03 .center-content .row {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
}

.bloco-03 .center-content .row .columns {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
}

.bloco-03 .center-content .slick-slider,
.bloco-03 .center-content .slick-slider.slick-initialized {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: 0px;
}

.bloco-03 .center-content .slick-slider .slick-list {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    max-height: 530px;
}

.bloco-03 .center-content .slick-slider .slick-track {
    display: flex;
    flex: 1 0 auto;
}

.bloco-03 .center-content .slick-slider .slick-slide {
    display: flex;
}

.bloco-03 .center-content .slick-slider .item {
    height: auto;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #333333;
    transform: scale(1);
    transition: 0.3s;
}

.bloco-03 .center-content .slick-slider .item a {
    width: 100%;
    height: 100%;
    float: left;
}

.bloco-03 .center-content .slick-slider .item img {
    width: 100%;
    height: auto;
    float: left;
    display: block;
}

/* Estilos para as setas de navegação */
.bloco-03 .center-content .slick-slider .slick-arrow {
    color: #666666;
    font-size: 50px;
    line-height: 52px;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-tap-highlight-color: transparent;
}

.bloco-03 .center-content .slick-slider .slick-arrow:hover {
    color: #FFF;
    text-decoration: none;
}

.bloco-03 .center-content .slick-slider .slick-disabled {
    opacity: .5;
    cursor: default;
}

.bloco-03 .center-content .slick-slider .slick-prev {
    left: 10px;
    margin-top: -56px;
}

.bloco-03 .center-content .slick-slider .slick-next {
    right: 10px;
    margin-top: -56px;
}

/* Estilos para os pontos de navegação */
.bloco-03 .center-content .slick-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    margin-top: -100px;
}

@media all and (max-width:600px){
    .bloco-03 .center-content .slick-dots {
       bottom: -3px;
    }
}

.bloco-03 .center-content .slick-dots li {
    display: inline-block;
    zoom: 1;
}
.slick-dots li button:before,
.slick-dots li.slick-active button:before{
    display: none;
}
.bloco-03 .center-content .slick-dots li button {
    width: 12px;
    height: 12px;
    margin: 5px 7px;
    background: #D6D6D6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px;
    border: none;
    padding: 0;
}

.bloco-03 .center-content .slick-dots li.slick-active button {
    background: #FFA800;
}

.bloco-03 .center-content .slick-dots li button:hover {
    background: #FFA800;
}



/** INFOS OPERA**/
.bloco-03 .center-content .positivo-infos {width:calc(50% - 25px);min-height:400px;height:auto;color:#ffffff;font-weight:400;padding:30px 0px 60px 0px;float:left;display:flex;flex-direction:column;}
.bloco-03 .center-content .positivo-infos .teatro {width:100%;height:auto;font-size:30px;line-height:20px;margin-bottom:24px;float:left;display:block;}
.bloco-03 .center-content .positivo-infos h2 {width:100%;height:auto;font-size:100px;line-height:94px;color:#ffffff;font-weight:900;float:left;display:block;}
.bloco-03 .center-content .positivo-infos .data {width:100%;height:auto;font-size:26px;line-height:30px;color:#FFA800;font-weight:400;margin-top:5px;float:left;display:block;}
.bloco-03 .center-content .positivo-infos .linha {width:50px;height:4px;margin:26px 0px 10px 0px;float:left;display:block;background-color:#FFA800;}
.bloco-03 .center-content .positivo-infos p {width:100%;height:auto;font-size:14px;line-height:20px;color:#ffffff;font-weight:400;margin:5px 0px 5px 0px;float:left;display:block;}
.bloco-03 .center-content .positivo-infos .abertura {width:100%;height:auto;font-size:14px;line-height:16px;color:#ffffff;font-weight:300;margin:10px 0px 5px 0px;float:left;display:block;}
.bloco-03 .center-content .positivo-infos .inicio {width:100%;height:auto;font-size:20px;line-height:22px;color:#ffffff;font-weight:600;margin:0px 0px 15px 0px;float:left;display:block;}
.bloco-03 .center-content .positivo-infos .title-local {width:100%;height:auto;font-size:12px;line-height:14px;color:#ffffff;font-weight:400;margin:0px 0px 4px 0px;float:left;display:block;}
.bloco-03 .center-content .positivo-infos .local {width:100%;height:auto;font-size:14px;line-height:18px;color:#ffffff;font-weight:500;margin:0px 0px 20px 0px;float:left;display:block;}
.bloco-03 .center-content .positivo-infos .btn-cta {width:250px;border:1px solid #ffc700;background-image:linear-gradient(45deg, #FFA800 50%, transparent 50%);background-position:100%;background-size:400%;transition:background 250ms ease-in-out;transition:0.3s;}
.bloco-03 .center-content .positivo-infos .btn-cta a {width:100%;height:auto;font-size:13px;color:#FFFFFF;line-height:40px;font-weight:500;text-align:center;padding:0px 25px;float:left;display:block;cursor:pointer;transition:0.2s;}
.bloco-03 .center-content .positivo-infos .btn-cta:hover {border:1px solid #FFA800;background-position:0;transition:0.3s;}
.bloco-03 .center-content .positivo-infos .btn-cta:hover a {color:#000000;}

/* --==============-- */
/* --== BLOCO 06 ==-- */
.bloco-06 {width:100%;height:auto;float:left;padding:0px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(87, 70, 150, 1);position:relative;z-index:10;}
.bloco-06 .center-content {max-width:1280px;width:100%;min-height:300px;height:auto;padding:100px 0px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(255, 255, 255, 0.0);}
.bloco-06 .center-content h2 {width:100%;height:auto;font-size:30px;line-height:34px;color:#ffffff;font-weight:900;text-align:center;margin:0px 0px 10px 0px;float:left;display:block;}
.bloco-06 .center-content p {max-width:800px;width:100%;height:auto;font-size:14px;line-height:20px;color:#ffffff;text-align:center;margin:8px 0px 8px 0px;float:left;}
.bloco-06 .center-content .btn-cta {width:250px;margin-top:30px;background-color:#ffc700;border:1px solid #ffc700;background-image:linear-gradient(45deg, #FFA800 50%, transparent 50%);background-position:100%;background-size:400%;transition:background 250ms ease-in-out;transition:0.3s;}
.bloco-06 .center-content .btn-cta a {width:100%;height:auto;font-size:13px;color:#000000;line-height:40px;font-weight:600;text-align:center;float:left;display:block;padding:0px 25px;cursor:pointer;transition:0.2s;}
.bloco-06 .center-content .btn-cta:hover {border:1px solid #FFA800;background-position:0;transition:0.3s;}
.bloco-06 .center-content .btn-cta:hover a {color:#000000;}

/* --==============-- */
/* --== BLOCO 07 ==-- */
.bloco-07 {width:100%;height:auto;float:left;padding:0px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(190, 190, 190, 1);position:relative;z-index:10;}
.bloco-07 .center-content {max-width:100%;width:100%;min-height:50px;height:auto;padding:0px;display:block;background:rgba(220, 220, 220, 1);}

/** CUSTOM CSS SLICK CAROUSEL **/
.bloco-07 .center-content .slick-slider {
    padding: 0px;
    max-height: fit-content;
    height: 100%;
}

@media all and (max-width:600px){
    .bloco-07 .center-content .slick-slider {
        max-height: 135px;
    }
}
.bloco-07 .center-content .slick-slider .item {
    height: auto;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #333333;
    transform: scale(1);
    transition: 0.3s;
}

.bloco-07 .center-content .slick-slider .item img {
    width: 100%;
    height: auto;
    float: left;
    display: block;
}

.bloco-07 .center-content .slick-slide {
    max-height: 239px;
}


/* --==============-- */
/* --== FALE CONOSCO BLOCO 01 ==-- */
.fale-conosco-bloco-01 {width:100%;height:auto;float:left;margin-top:133px;padding:108px 40px 100px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(20, 20, 20, 1);position:relative;z-index:10;}
.fale-conosco-bloco-01 .center-content {max-width:1024px;width:100%;min-height:10px;height:auto;display:flex;flex-direction:row;flex-wrap:wrap;justify-content:space-between;}
.fale-conosco-bloco-01 .center-content .box-info {width:350px;height:auto;float:left;display:block;}
.fale-conosco-bloco-01 .center-content .box-info h1 {width:100%;height:auto;font-size:64px;line-height:66px;color:#FFFFFF;font-weight:900;margin:0px 0px 10px 0px;float:left;display:block;}
.fale-conosco-bloco-01 .center-content .box-info h2 {width:100%;height:auto;font-size:24px;line-height:28px;color:#FFFFFF;font-weight:400;margin:0px 0px 30px 0px;float:left;display:block;}
.fale-conosco-bloco-01 .center-content .box-info p {width:100%;height:auto;font-size:16px;line-height:25px;color:#ffffff;font-weight:400;margin:6px 0px;float:left;display:block;}
.fale-conosco-bloco-01 .center-content .box-info .info-link {color:#FFFFFF;font-weight:400;}
.fale-conosco-bloco-01 .center-content .box-info .info-link:hover {color:#FFA800;}
.fale-conosco-bloco-01 .center-content .box-form {width:calc(100% - 410px);height:auto;float:left;display:block;}
.fale-conosco-bloco-01 .center-content .box-form h3 {width:100%;height:auto;font-size:30px;line-height:34px;color:#FFFFFF;font-weight:600;margin:0px 0px 20px 0px;float:left;display:block;}
.fale-conosco-bloco-01 .center-content .box-form .form-fale-conosco {width:100%;height:auto;float:left;display:flex;flex-direction:column;}
.fale-conosco-bloco-01 .center-content .box-form .form-fale-conosco label {width:100%;height:auto;font-size:16px;line-height:22px;color:#FFFFFF;font-weight:400;margin:0px 0px 3px 0px;float:left;display:block;}
.fale-conosco-bloco-01 .center-content .box-form .form-fale-conosco input {width:100%;height:50px;margin-bottom:15px;padding:0px 16px;float:left;display:block;border:1px #dfdfdf solid;background:#ffffff;}
.fale-conosco-bloco-01 .center-content .box-form .form-fale-conosco textarea {width:100%;height:150px;padding:20px 16px;float:left;display:block;border:1px #dfdfdf solid;background:#ffffff;}
.fale-conosco-bloco-01 .center-content .box-form .form-fale-conosco .box-btn {width:100%;height:auto;float:left;display:flex;flex-direction:column;align-items:flex-end;}
.fale-conosco-bloco-01 .center-content .box-form .form-fale-conosco button {width:250px;height:auto;font-size:12px;line-height:50px;font-weight:600;float:right;margin-top:30px;padding:0px 10px;background-color:#ffc700;border:1px solid #ffc700;background-image:linear-gradient(45deg, #FFA800 50%, transparent 50%);background-position:100%;background-size:400%;transition:background 250ms ease-in-out;}
.fale-conosco-bloco-01 .center-content .box-form .form-fale-conosco button:hover {border:1px solid #FFA800;background-position:0;transition:0.3s;}

/* --==============-- */
/* --== FAQ BLOCO 01 ==-- */
.faq-bloco-01 {width:100%;height:auto;float:left;margin-top:130px;padding:0px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(20, 20, 20, 1);position:relative;z-index:10;}
.faq-bloco-01 .center-content {max-width:1280px;width:100%;min-height:10px;height:auto;padding:100px 0px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(255, 255, 255, 0.0);}
.faq-bloco-01 .center-content h2 {width:100%;height:auto;font-size:30px;line-height:34px;color:#ffffff;font-weight:900;text-align:center;margin:0px 0px 10px 0px;float:left;display:block;}
.faq-bloco-01 .center-content .sub-titulo {max-width:600px;width:100%;height:auto;font-size:14px;line-height:18px;color:#cccccc;font-weight:400;text-align:center;margin-bottom:30px;float:left;display:block;}

.faq-bloco-01 .center-content .accordion-menu {max-width:1000px;width:100%;display:inline-block;position:relative;}
.faq-bloco-01 .center-content h3 {font-size:18px;line-height:34px;color:#000000;font-weight:500;letter-spacing:1px;padding-right:20px;display:block;cursor:pointer;}

.faq-bloco-01 .center-content ul {width:auto;height:auto;perspective:900;display:block;}
.faq-bloco-01 .center-content ul li {margin:10px 0px;padding:10px 20px;position:relative;border-radius:3px;background-color:#fff;box-shadow:0 2px 5px 0 rgba(0, 0, 0, 0.2),	0 2px 2px 0 rgba(255, 255, 255, 0.19);}
.faq-bloco-01 .center-content ul li p {font-size:13px;line-height:24px;color:rgba(48, 69, 92, 1.0);letter-spacing:1px;font-weight:400;margin-top:5px;padding-right: 30px;position:relative;overflow:hidden;opacity:1;transform:translate(0, 0);z-index:2;}
.faq-bloco-01 .center-content ul li:nth-of-type(1) {animation-delay:0.5s;}
.faq-bloco-01 .center-content ul li:nth-of-type(2) {animation-delay:0.75s;}
.faq-bloco-01 .center-content ul li:nth-of-type(3) {animation-delay:1.0s;}
.faq-bloco-01 .center-content ul li:nth-of-type(4) {animation-delay:1.25s;}
.faq-bloco-01 .center-content ul li:nth-of-type(5) {animation-delay:1.50s;}
.faq-bloco-01 .center-content ul li:nth-of-type(6) {animation-delay:1.75s;}
.faq-bloco-01 .center-content ul li:nth-of-type(7) {animation-delay:2.0s;}
.faq-bloco-01 .center-content ul li:nth-of-type(8) {animation-delay:2.25s;}
.faq-bloco-01 .center-content ul li:nth-of-type(9) {animation-delay:2.5s;}
.faq-bloco-01 .center-content ul li:nth-of-type(10) {animation-delay:2.75s;}
.faq-bloco-01 .center-content ul li:nth-of-type(11) {animation-delay:3.0s;}
.faq-bloco-01 .center-content ul li:nth-of-type(12) {animation-delay:3.25s;}
.faq-bloco-01 .center-content ul li:nth-of-type(13) {animation-delay:3.50s;}
.faq-bloco-01 .center-content ul li:nth-of-type(14) {animation-delay:3.75s;}
.faq-bloco-01 .center-content ul li:nth-of-type(15) {animation-delay:4.0s;}
.faq-bloco-01 .center-content ul li:nth-of-type(16) {animation-delay:4.25s;}
.faq-bloco-01 .center-content ul li:nth-of-type(17) {animation-delay:4.5s;}
.faq-bloco-01 .center-content ul li:nth-of-type(18) {animation-delay:4.75s;}
.faq-bloco-01 .center-content ul li:nth-of-type(19) {animation-delay:5.0s;}
.faq-bloco-01 .center-content ul li:nth-of-type(20) {animation-delay:5.25s;}
.faq-bloco-01 .center-content ul li .arrow {margin-top:14px;right:0;position:absolute;transform:translate(-28px, 0);}
.faq-bloco-01 .center-content ul li .fas{color:#f6483b;font-size:15px;margin-right:10px;}
.faq-bloco-01 .center-content ul li .arrow:before, ul li .arrow:after {width:3px;height:9px;content:"";position:absolute;background-color:#574696;}
.faq-bloco-01 .center-content ul li .arrow:before {transform:translate(-2px, 0) rotate(45deg);}
.faq-bloco-01 .center-content ul li .arrow:after {transform:translate(2px, 0) rotate(-45deg);}
.faq-bloco-01 .center-content ul li input[type=checkbox] {width:100%;height:100%;position:absolute;top:0px;left:0px;cursor:pointer;z-index:1;opacity:0;}
.faq-bloco-01 .center-content ul li input[type=checkbox]:checked ~ p {max-height:0;margin-top:0;opacity:0;transform:translate(0, 50%);}
.faq-bloco-01 .center-content ul li input[type=checkbox]:checked ~ .arrow:before {transform: translate(2px, 0) rotate(45deg);}
.faq-bloco-01 .center-content ul li input[type=checkbox]:checked ~ .arrow:after {transform: translate(-2px, 0) rotate(-45deg);}
.faq-bloco-01 .center-content .transition, p, ul li .arrow:before, ul li .arrow:after {transition: all 0.25s ease-in-out;}
.faq-bloco-01 .center-content .flipIn, h1, ul li {animation:flipdown 0.5s ease both;}
.faq-bloco-01 .center-content .no-select, h2 {-webkit-tap-highlight-color:transparent;-webkit-touch-callout:none;user-select:none;}
.faq-bloco-01 .center-content ul li p a {font-weight:800;color:rgba(48, 69, 92, 1.0);}
.faq-bloco-01 .center-content ul li p a:hover {color:#FFA800;}

/* --==============-- */
/* --== POLÍTICA BLOCO 01 ==-- */
.politica-bloco-01 {width:100%;height:auto;float:left;margin-top:130px;padding:108px 40px 100px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(20, 20, 20, 1);position:relative;z-index:10;}
.politica-bloco-01 .center-content {max-width:1024px;width:100%;min-height:100px;height:auto;display:flex;flex-direction:column;align-items:center;}
.politica-bloco-01 .center-content h2 {width:100%;height:auto;font-size:44px;line-height:47px;color:#ffffff;font-weight:900;font-style:italic;margin:0px 0px 20px 0px;float:left;display:block;}
.politica-bloco-01 .center-content h2 span {width:100%;height:auto;font-size:30px;line-height:33px;color:#ffffff;font-weight:500;font-style:italic;float:left;display:block;}
.politica-bloco-01 .center-content h3 {width:100%;height:auto;font-size:20px;line-height:30px;color:#ffffff;font-weight:800;margin:30px 0px 10px 0px;float:left;display:block;}
.politica-bloco-01 .center-content p {width:100%;height:auto;font-size:14px;line-height:24px;color:#cccccc;font-weight:400;margin:0px 0px 15px 0px;float:left;display:block;}
.politica-bloco-01 .center-content p a {color:#FFFFFF;font-weight:400;}
.politica-bloco-01 .center-content p a:hover {color:#FFA800;}
/* --==============-- */
/* --== LOGIN BLOCO 01 ==-- */
.login-bloco-01 {width:100%;height:auto;float:left;margin-top:133px;padding:108px 40px 100px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(20, 20, 20, 1);position:relative;z-index:10;}
.login-bloco-01 .center-content {max-width:1024px;width:100%;min-height:500px;height:auto;display:flex;flex-direction:row;flex-wrap:wrap;justify-content:space-between;background-color:#ffffff;box-shadow: 0 0 35px 10px rgba(0,0,0,0.7);}

/* --== LOGIN ==-- */
.login-bloco-01 .center-content .box-login {width:50%;height:auto;min-height:100px;padding:60px 40px 40px 40px;float:left;display:flex;flex-direction:column;justify-content:center;background-color:#ffffff;}
.login-bloco-01 .center-content .box-login h1 {width:100%;height:auto;font-size:30px;line-height:34px;color:#000000;font-weight:600;margin:0px 0px 5px 0px;float:left;display:block;}
.login-bloco-01 .center-content .box-login p {width:100%;height:auto;font-size:14px;line-height:18px;color:#666666;font-weight:300;margin:0px 0px 20px 0px;float:left;display:block;}
.login-bloco-01 .center-content .box-login .form-login {width:100%;height:auto;float:left;display:flex;flex-direction:column;}
.login-bloco-01 .center-content .box-login .form-login label {width:100%;height:auto;font-size:16px;line-height:22px;color:#000000;font-weight:400;margin:0px 0px 3px 0px;float:left;display:block;}
.login-bloco-01 .center-content .box-login .form-login input {width:100%;height:50px;margin-bottom:5px;padding:0px 16px;float:left;display:block;border:1px #dfdfdf solid;}
.login-bloco-01 .center-content .box-login .form-login .box-recuperacao {width:100%;height:auto;margin:0px 0px 10px 0px;float:left;display:flex;flex-direction:column;align-items:flex-end;}
.login-bloco-01 .center-content .box-login .form-login .box-recuperacao .link-recuperar {width:auto;height:auto;font-size:12px;line-height:17px;color:#999999;font-weight:400;text-align:right;float:left;display:block;}
.login-bloco-01 .center-content .box-login .form-login .box-recuperacao .link-recuperar:hover {color:#FFA800;cursor:pointer;}
.login-bloco-01 .center-content .box-login .form-login .box-btn {width:100%;height:auto;float:left;display:flex;flex-direction:column;align-items:flex-end;}
.login-bloco-01 .center-content .box-login .form-login .btn-salvar {width:100%;height:auto;font-size:12px;line-height:50px;font-weight:600;float:right;margin-top:10px;padding:0px 10px;background-color:#ffc700;border:1px solid #ffc700;background-image:linear-gradient(45deg, #FFA800 50%, transparent 50%);background-position:100%;background-size:400%;transition:background 250ms ease-in-out;transition:0.3s;}
.login-bloco-01 .center-content .box-login .form-login .btn-salvar:hover {border:1px solid #FFA800;background-position:0;transition:0.3s;}

/* --== RECUPERAR E-MAIL ==-- */
.login-bloco-01 .center-content .box-email {width:50%;height:auto;min-height:100px;padding:60px 40px 100px 40px;float:left;display:none;flex-direction:column;justify-content:center;background-color:#ffffff;position:relative;}
.login-bloco-01 .center-content .box-email .btn-voltar {width:auto;height:auto;font-size:16px;line-height:20px;color:#666666;font-weight:400;text-align:right;float:left;display:block;position:absolute;top:30px;right:40px;}
.login-bloco-01 .center-content .box-email .btn-voltar:hover {color:#FFA800;cursor:pointer;}
.login-bloco-01 .center-content .box-email h1 {width:100%;height:auto;font-size:30px;line-height:34px;color:#000000;font-weight:600;margin:0px 0px 5px 0px;float:left;display:block;}
.login-bloco-01 .center-content .box-email p {width:100%;height:auto;font-size:14px;line-height:18px;color:#666666;font-weight:300;margin:0px 0px 20px 0px;float:left;display:block;}
.login-bloco-01 .center-content .box-email .form-login {width:100%;height:auto;float:left;display:flex;flex-direction:column;}
.login-bloco-01 .center-content .box-email .form-login label {width:100%;height:auto;font-size:16px;line-height:22px;color:#000000;font-weight:400;margin:0px 0px 3px 0px;float:left;display:block;}
.login-bloco-01 .center-content .box-email .form-login input {width:100%;height:50px;margin-bottom:5px;padding:0px 16px;float:left;display:block;border:1px #dfdfdf solid;}
.login-bloco-01 .center-content .box-email .form-login .box-btn {width:100%;height:auto;float:left;display:flex;flex-direction:column;align-items:flex-end;}
.login-bloco-01 .center-content .box-email .form-login .btn-salvar {width:100%;height:auto;font-size:12px;line-height:50px;font-weight:600;float:right;margin-top:10px;padding:0px 10px;background-color:#ffc700;border:1px solid #ffc700;background-image:linear-gradient(45deg, #FFA800 50%, transparent 50%);background-position:100%;background-size:400%;transition:background 250ms ease-in-out;transition:0.3s;}
.login-bloco-01 .center-content .box-email .form-login .btn-salvar:hover {border:1px solid #FFA800;background-position:0;transition:0.3s;}
.login-bloco-01 .center-content .box-email .box-alerta {width:calc(100% - 80px);height:auto;float:left;display:block;position:absolute;bottom:10px;}
.login-bloco-01 .center-content .box-email .box-alerta .alerta {width:100%;height:auto;font-size:10px;line-height:14px;color:#999999;font-weight:400;text-align:justify;float:left;display:block;}
.login-bloco-01 .center-content .box-email .box-alerta .alerta a {color:#666666;font-weight:600;}
.login-bloco-01 .center-content .box-email .box-alerta .alerta a:hover {color:#FFA800;}

/* --== RECUPERAR SENHA ==-- */
.login-bloco-01 .center-content .box-senha {width:50%;height:auto;min-height:100px;padding:60px 40px 100px 40px;float:left;display:none;flex-direction:column;justify-content:center;background-color:#ffffff;position:relative;}
.login-bloco-01 .center-content .box-senha .btn-voltar {width:auto;height:auto;font-size:16px;line-height:20px;color:#666666;font-weight:400;text-align:right;float:left;display:block;position:absolute;top:30px;right:40px;}
.login-bloco-01 .center-content .box-senha .btn-voltar:hover {color:#FFA800;cursor:pointer;}
.login-bloco-01 .center-content .box-senha h1 {width:100%;height:auto;font-size:30px;line-height:34px;color:#000000;font-weight:600;margin:0px 0px 5px 0px;float:left;display:block;}
.login-bloco-01 .center-content .box-senha p {width:100%;height:auto;font-size:14px;line-height:18px;color:#666666;font-weight:300;margin:0px 0px 20px 0px;float:left;display:block;}
.login-bloco-01 .center-content .box-senha .form-login {width:100%;height:auto;float:left;display:flex;flex-direction:column;}
.login-bloco-01 .center-content .box-senha .form-login label {width:100%;height:auto;font-size:16px;line-height:22px;color:#000000;font-weight:400;margin:0px 0px 3px 0px;float:left;display:block;}
.login-bloco-01 .center-content .box-senha .form-login input {width:100%;height:50px;margin-bottom:5px;padding:0px 16px;float:left;display:block;border:1px #dfdfdf solid;}
.login-bloco-01 .center-content .box-senha .form-login .box-btn {width:100%;height:auto;float:left;display:flex;flex-direction:column;align-items:flex-end;}
.login-bloco-01 .center-content .box-senha .form-login .btn-salvar {width:100%;height:auto;font-size:12px;line-height:50px;font-weight:600;float:right;margin-top:10px;padding:0px 30px;background-color:#ffc700;border:1px solid #ffc700;background-image:linear-gradient(45deg, #FFA800 50%, transparent 50%);background-position:100%;background-size:400%;transition:background 250ms ease-in-out;transition:0.3s;}
.login-bloco-01 .center-content .box-senha .form-login .btn-salvar:hover {border:1px solid #FFA800;background-position:0;transition:0.3s;}
.login-bloco-01 .center-content .box-senha .box-alerta {width:calc(100% - 80px);height:auto;float:left;display:block;position:absolute;bottom:10px;}
.login-bloco-01 .center-content .box-senha .box-alerta .alerta {width:100%;height:auto;font-size:10px;line-height:14px;color:#999999;font-weight:400;text-align:justify;float:left;display:block;}
.login-bloco-01 .center-content .box-senha .box-alerta .alerta a {color:#666666;font-weight:600;}
.login-bloco-01 .center-content .box-senha .box-alerta .alerta a:hover {color:#FFA800;}

/* --== BOX DE IMAGENS ==-- */
.login-bloco-01 .center-content .box-bg {width:50%;height:auto;min-height:100px;display:flex;background-color:#000000;}
.login-bloco-01 .center-content .box-bg .quotes {display:none;}
.login-bloco-01 .center-content .box-bg .box-img {width:100%;height:auto;min-height:100px;}
.login-bloco-01 .center-content .box-bg .img-bg-01 {background:url("../hotsite/img_01.jpg") no-repeat center center;background-attachment:local;background-size:cover;}
.login-bloco-01 .center-content .box-bg .img-bg-02 {background:url("../hotsite/img_02.jpg") no-repeat center center;background-attachment:local;background-size:cover;}
.login-bloco-01 .center-content .box-bg .img-bg-03 {background:url("../hotsite/img_03.jpg") no-repeat center center;background-attachment:local;background-size:cover;}
.login-bloco-01 .center-content .box-bg .img-bg-04 {background:url("../hotsite/img_04.jpg") no-repeat center center;background-attachment:local;background-size:cover;}
.login-bloco-01 .center-content .box-bg .img-bg-05 {background:url("../hotsite/img_05.jpg") no-repeat center center;background-attachment:local;background-size:cover;}
.login-bloco-01 .center-content .box-bg .img-bg-06 {background:url("../hotsite/img_06.jpg") no-repeat center center;background-attachment:local;background-size:cover;}
.login-bloco-01 .center-content .box-bg .img-bg-07 {background:url("../hotsite/img_07.jpg") no-repeat center center;background-attachment:local;background-size:cover;}

/* --== LINK CRIAR CONTA ==-- */
.login-bloco-01 .box-cadastro {width:100%;height:auto;margin-top:20px;float:left;display:flex;flex-direction:column;align-items:center;}
.login-bloco-01 .box-cadastro p {width:auto;height:auto;font-size:13px;line-height:18px;color:#ffffff;font-weight:300;text-align:center;float:left;display:block;}
.login-bloco-01 .box-cadastro p a {color:#ffc700;font-weight:600;}
.login-bloco-01 .box-cadastro p a:hover {color:#FFA800;}

/* --==============-- */
/* --== CADASTRO BLOCO 01 ==-- */
.cadastro-bloco-01 {width:100%;height:auto;float:left;margin-top:133px;padding:108px 40px 100px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background: rgba(20, 20, 20, 1);position:relative;z-index:10;}
.cadastro-bloco-01 .center-content {max-width:1024px;width:100%;min-height:1000px;height:auto;display:flex;flex-direction:column;align-items:center;}
.cadastro-bloco-01 .center-content .box-cadastro {width:100%;height:auto;padding:60px 40px 40px 40px;float:left;display:flex;flex-direction:column;justify-content:center;background-color:#ffffff;}
.cadastro-bloco-01 .center-content .box-cadastro h1 {width:100%;height:auto;font-size:30px;line-height:34px;color:#000000;font-weight:600;margin:0px 0px 5px 0px;float:left;display:block;}
.cadastro-bloco-01 .center-content .box-cadastro .subtitle {width:100%;height:auto;font-size:14px;line-height:18px;color:#666666;font-weight:300;margin:0px 0px 20px 0px;float:left;display:block;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro {width:100%;height:auto;float:left;display:flex;flex-direction:row;flex-wrap:wrap;justify-content:space-between;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .box-100 {width:100%;height:auto;margin-bottom:15px;float:left;display:block;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .box-50 {width:calc(50% - 15px);height:auto;margin-bottom:15px;float:left;display:block;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .box-30 {width:calc(33.333% - 20px);height:auto;margin-bottom:20px;float:left;display:block;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .color-red {color:#DB0003;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro label {width:100%;height:auto;font-size:16px;line-height:22px;color:#000000;font-weight:400;margin:0px 0px 3px 0px;float:left;display:block;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro input {width:100%;height:50px;margin-bottom:5px;padding:0px 16px;float:left;display:block;background-color:#FFFFFF;border:1px #dfdfdf solid;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro p {width:100%;height:auto;font-size:12px;line-height:18px;color:#999999;font-weight:300;float:left;display:block;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .box-aceite {width:100%;height:auto;float:left;margin-top:20px;display:flex;flex-direction:column;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .box-aceite label {width:100%;height:auto;font-size:16px;line-height:22px;color:#000000;font-weight:400;margin:0px 0px 5px 0px; float:left;display:block;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .box-aceite .box-input {width:100%;height:auto;float:left;margin-top:0px;display:flex;flex-direction:row;flex-wrap:wrap;justify-content:space-between;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .box-aceite .box-input .input-aceite {width:20px;height:20px;float:left;display:block;background-color:none;border:1px #dfdfdf solid;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .box-aceite .box-input .aceite-txt {width:calc(100% - 30px);height:auto;font-size:12px;line-height:16px;color:#333333;font-weight:300;margin-bottom:0px;float:left;display:block;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .box-aceite .box-input .aceite-txt a {color:#000000;font-weight:500;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .box-aceite .box-input .aceite-txt a:hover {color:#FFA800;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .box-btn {width:100%;height:auto;float:left;margin-top:30px;display:flex;flex-direction:column;align-items:flex-end;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .btn-salvar {width:50%;height:auto;font-size:12px;line-height:50px;font-weight:600;float:right;margin-top:10px;padding:0px 10px;background-color:#ffc700;border:1px solid #ffc700;background-image:linear-gradient(45deg, #FFA800 50%, transparent 50%);background-position:100%;background-size:400%;transition:background 250ms ease-in-out;transition:0.3s;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .btn-salvar:hover {border:1px solid #FFA800;background-position:0;transition:0.3s;}

/* --== LINK CRIAR CONTA ==-- */
.cadastro-bloco-01 .box-login {width:100%;height:auto;margin-top:20px;float:left;display:flex;flex-direction:column;align-items:center;}
.cadastro-bloco-01 .box-login p {width:auto;height:auto;font-size:13px;line-height:18px;color:#ffffff;font-weight:300;text-align:center;float:left;display:block;}
.cadastro-bloco-01 .box-login p a {color:#ffc700;font-weight:600;}
.cadastro-bloco-01 .box-login p a:hover {color:#FFA800;}


/* --== RESPONSIVO =========================================-- */
/*--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--*/
/* --== RESPONSIVO =========================================-- */

/* --== MEDIA 1080px ==-- */
@media all and (max-width:1080px){
/* --==-- */
.bloco-03 .center-content .positivo-infos h2 {font-size:90px;line-height:80px;}
}

/* --== MEDIA 1050px ==-- */
@media all and (max-width:1050px){
/* --==-- */
/* --== PADDING PADRÃO HEADER|MAIN ==-- */
.modelo-bloco-01, .bloco-01, .fale-conosco-bloco-01, .politica-bloco-01, .login-bloco-01, .cadastro-bloco-01 {margin-top:58px;}
/* --== PADDING PADRÃO HOME ==-- */
.modelo-bloco-01, .bloco-02, .bloco-03, .bloco-05, .bloco-06, .faq-bloco-01 {padding:0px 20px;}
.bloco-01 {padding:0px;}
.bloco-03 {padding:80px 20px;}
/* --== PADDING PADRÃO BLOCO 01 ==-- */
.bloco-01 .center-content {padding:0px;}
.bloco-01 .center-content .owl-carousel{padding:0px;}
.bloco-01 .center-content .owl-carousel .item {height:calc(100vh - 150px);}
.bloco-01 .center-content .owl-carousel .item a {padding:0px 20px;}
}

/* --== MEDIA 1024px ==-- */
@media all and (max-width:1024px){
/* --==-- */
.bloco-05 .center-content ul .ativo a h2 {font-size:23px;line-height:28px;}
.bloco-05 .center-content ul .ativo a h2 span{font-size:14px;line-height:16px;}
.bloco-05 .center-content ul .inativo a h2 {font-size:23px;line-height:28px;}
.bloco-05 .center-content ul .inativo a h2 span{font-size:14px;line-height:16px;}
.bloco-03 .center-content .positivo-infos h2 {font-size:80px;line-height:70px;}
}
	
/* --== MEDIA 900px ==-- */
@media all and (max-width:900px){
/* --==-- */
.bloco-03 .center-content .positivo-infos h2 {font-size:70px;line-height:60px;}
/* --== FALE CONOSCO BLOCO 01 ==-- */
.fale-conosco-bloco-01 .center-content .box-info {width:100%;}
.fale-conosco-bloco-01 .center-content .box-form {width:100%;margin-top:60px;}
.fale-conosco-bloco-01 .center-content .box-form .form-fale-conosco button {width:100%;}
/* --== LOGIN ==-- */
.login-bloco-01 .center-content .box-login {width:100%;}
/* --== RECUPERAR E-MAIL ==-- */
.login-bloco-01 .center-content .box-email {width:100%;}
/* --== RECUPERAR SENHA ==-- */
.login-bloco-01 .center-content .box-senha {width:100%;}
/* --== BOX DE IMAGENS ==-- */
.login-bloco-01 .center-content .box-bg {width:100%;min-height:200px;}
/* --== CADASTRO BLOCO 01 ==-- */
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .box-30 {width:calc(50% - 15px);}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .btn-salvar {width:100%;}
}

/* --== MEDIA 850px ==-- */
@media all and (max-width:850px){
/* --==-- */
.bloco-02 .center-content {padding:100px 0px 70px 0px;}
.bloco-05 .center-content ul li {width:100%;margin-bottom:30px;}
.bloco-05 .center-content ul li:last-child {margin-bottom:0px;}
.bloco-03 .center-content {flex-wrap:wrap-reverse;}
.bloco-03 .center-content .positivo-fotos {width:100%;min-height:450px;}
.bloco-03 .center-content .positivo-infos {width:100%;padding:30px 0px 30px 0px;}
.bloco-03 .center-content .positivo-infos .btn-cta {width:100%;}
}

/* --== MEDIA 800px ==-- */
@media all and (max-width:800px){
/* --==-- */
.bloco-01 .center-content {padding-bottom:40px;}
.bloco-01 .center-content .box-banner .img-desktop {display:none;}
.bloco-01 .center-content .box-banner .img-mobile {display:block;}
.bloco-02 .center-content ul li {max-width:50%;}
}
	
/* --== MEDIA 768px ==-- */
@media all and (max-width:768px){
/* --==-- */
/* --== FALE CONOSCO BLOCO 01 ==-- */
.fale-conosco-bloco-01 {padding:108px 20px 100px 20px;}
.fale-conosco-bloco-01 .center-content .box-info h1 {font-size:38px;line-height:42px;}
/* --== POLITICA DE PRIVACIDADE BLOCO 01 ==-- */
.politica-bloco-01 {padding:108px 20px 100px 20px;}
.politica-bloco-01 .center-content h2 {font-size:38px;line-height:42px;}
.politica-bloco-01 .center-content h2 span {font-size:24px;line-height:30px;}
/* --== LOGIN BLOCO 01 ==-- */
.login-bloco-01 {padding:108px 20px 100px 20px;}
.login-bloco-01 .center-content .box-login {padding: 60px 20px 40px 20px;}
/* --== CADASTRO BLOCO 01 ==-- */
.cadastro-bloco-01 {padding:108px 20px 100px 20px;}
.cadastro-bloco-01 .center-content .box-cadastro {padding:60px 20px 40px 20px;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .box-50 {width:100%;}
.cadastro-bloco-01 .center-content .box-cadastro .form-cadastro .box-30 {width:100%;}
}

/* --== MEDIA 500px ==-- */
@media all and (max-width:500px){
/* --==-- */
.bloco-02 .center-content ul li {max-width:100%;}
.bloco-06 .center-content .btn-cta {width:100%;transition:0.3s;}
.bloco-03 .center-content ul li {max-width:100%;}
.bloco-03 .center-content .positivo-fotos {width:100%;min-height:400px;}
.bloco-03 .center-content .positivo-infos h2 {font-size:74px;line-height:72px;}
.bloco-03 .center-content .positivo-infos .btn-cta {width:100%;}
/* --== LINK CRIAR CONTA ==-- */
.login-bloco-01 .box-cadastro p a {width:100%;display:block;}
/* --== LINK login ==-- */
.cadastro-bloco-01 .box-login p a {width:100%;display:block;}
}

/* --== MEDIA 450px ==-- */
@media all and (max-width:450px){
/* --==-- */
.bloco-03 .center-content .positivo-infos h2 {font-size:56px;line-height:50px;}
}

/* --== MEDIA 399px ==-- */
@media all and (max-width:399px){
/* --==-- */
.bloco-03 .center-content .positivo-infos .data {width:100%;height:auto;font-size:22px;}
}

.accordion-menu .accordion-content {
    display: none;
  }
  
  .accordion-menu li.active .accordion-content {
    display: block;
  }

/* ============================================ */
/* CSS By http://nerdweb.com.br */
/* ============================================ */