/* ============================================ */
/* CSS By http://nerdweb.com.br */
/* ============================================ */


/* --== BODY ==-- */
.site {min-height:100vh;height:100%;display:flex;flex-direction:column;}

/* --== CONTENT ==-- */
.site-container {flex:1;padding-top:60px;}


/* --== FORMS CAMPOS ==-- */
input[type="text"],
input[type="email"],
input[type="url"],
input[type="tel"],
select,
textarea {font-size:16px;}

/* --== PLACEHOLDER ==-- */
::-webkit-input-placeholder {font-size:14px;font-weight:200;color:#999999;}
:-moz-placeholder {font-size:14px;font-weight:200;color:#999999;}
::-moz-placeholder {font-size:14px;font-weight:200;color:#999999;}


/* --=================-- */
/* --== CAROUSEL 01 ==-- */
.carousel-01 {width:100%;height:auto;float:left;padding:0px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(230, 230, 230, 1);position:relative;z-index:10;}
.carousel-01 .center-content {max-width:1280px;width:100%;min-height:50px;height:auto;padding:50px 0px;display:flex;flex-direction:column;background:rgba(255, 255, 255, 1);}

/** CUSTOM CSS OWL CAROUSEL  **/
.carousel-01 .center-content .owl-carousel {padding: 0px 0px;}
.carousel-01 .center-content .owl-carousel .item {height:auto;display:flex;flex:1;flex-direction:row;align-items:center;justify-content:center;background:#333333;transform:scale(1);transition:0.3s;}
.carousel-01 .center-content .owl-carousel .item a {width:100%;height:100%;float:left;}
.carousel-01 .center-content .owl-carousel .item a img {width:100%;height:auto;float:left;display:block;}
.carousel-01 .center-content .owl-theme .owl-nav {text-align:center;-webkit-tap-highlight-color:transparent;position:relative;}
.carousel-01 .center-content .owl-theme .owl-nav [class*=owl-]{color:#666666;font-size:50px;line-height:52px;display:inline-block;cursor:pointer;}
.carousel-01 .center-content .owl-theme .owl-nav [class*=owl-]:hover{color:#FFF;text-decoration:none;}
.carousel-01 .center-content .owl-theme .owl-nav .disabled {opacity:.5;cursor:default;}
.carousel-01 .center-content .owl-theme .owl-nav.disabled+.owl-dots {margin-top:10px}
.carousel-01 .center-content .owl-carousel .owl-nav .owl-prev {width:auto;height:auto;float:left;margin-top:-180px;margin-left:10px;}
.carousel-01 .center-content .owl-carousel .owl-nav .owl-next {width:auto;height:auto;float:right;margin-top:-180px;margin-right:10px}
.carousel-01 .center-content .owl-theme .owl-dots {text-align:center;-webkit-tap-highlight-color:transparent;position:relative;}
.carousel-01 .center-content .owl-theme .owl-dots .owl-dot {display:inline-block;zoom:1;margin-top:-80px;}
.carousel-01 .center-content .owl-theme .owl-dots .owl-dot span {width:12px;height:12px;margin:5px 7px;background:#D6D6D6;display:block;-webkit-backface-visibility:visible;transition:opacity .2s ease;border-radius:30px;}
.carousel-01 .center-content .owl-theme .owl-dots .owl-dot.active span {background:#004BFD;}
.carousel-01 .center-content .owl-theme .owl-dots .owl-dot:hover span {background:#004BFD;}

/* Feel free to change duration  */ 
.carousel-01 .center-content .animated {-webkit-animation-duration:1000 ms;animation-duration:1000ms;-webkit-animation-fill-mode:both;animation-fill-mode:both;}
/* .owl-animated-out - only for current item */ 
/* This is very important class. Use z-index if you want move Out item above In item */ 
.carousel-01 .center-content .owl-animated-out {z-index:1;}
/* .owl-animated-in - only for upcoming item
/* This is very important class. Use z-index if you want move In item above Out item */ 
.carousel-01 .center-content .owl-animated-in {z-index:0}
/* .fadeOut is style taken from Animation.css and this is how it looks in owl.carousel.css:  */ 
.carousel-01 .center-content .fadeOut {-webkit-animation-name:fadeOut;animation-name:fadeOut;}
@-webkit-keyframes fadeOut { 0% {opacity:1;} 100% {opacity:0;} }
@keyframes fadeOut { 0% {opacity:1;} 100% {opacity:0;} }

.carousel-01 .center-content .btn-cta {width:100%;height:auto;float:left;margin-top:30px;display:flex;flex-direction:column;justify-content:center;align-items:center;}
.carousel-01 .center-content .btn-cta a {max-width:250px;width:100%;height:auto;font-size:14px;line-height:50px;color:#000000;font-weight:600;text-align:center;float:left;border:2px #000000 solid;border-radius:50px;}
.carousel-01 .center-content .btn-cta a:hover {color:#ffffff;background-color:#000000; border:2px #000000 solid;}


/* --=================-- */
/* --== CAROUSEL 02 ==-- */
.carousel-02 {width:100%;height:auto;float:left;padding:0px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(190, 190, 190, 1);position:relative;z-index:10;}
.carousel-02 .center-content {max-width:1280px;width:100%;min-height:500px;height:auto;padding:100px 0px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(220, 220, 220, 1);}


/* --=================-- */
/* --== CAROUSEL 03 ==-- */
.carousel-03 {width:100%;height:auto;float:left;padding:0px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(230, 230, 230, 1);position:relative;z-index:10;}
.carousel-03 .center-content {max-width:1280px;width:100%;min-height:500px;height:auto;padding:100px 0px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(255, 255, 255, 1);}


/* --=================-- */
/* --== CAROUSEL 04 ==-- */
.carousel-04 {width:100%;height:auto;float:left;padding:0px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(190, 190, 190, 1);position:relative;z-index:10;}
.carousel-04 .center-content {max-width:1280px;width:100%;min-height:500px;height:auto;padding:100px 0px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(220, 220, 220, 1);}


/* --=================-- */
/* --== CAROUSEL 05 ==-- */
.carousel-05 {width:100%;height:auto;float:left;padding:0px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(230, 230, 230, 1);position:relative;z-index:10;}
.carousel-05 .center-content {max-width:1280px;width:100%;min-height:500px;height:auto;padding:100px 0px;display:flex;flex-direction:column;justify-content:center;align-items:center;background:rgba(255, 255, 255, 1);}


/* --== RESPONSIVO =========================================-- */
/*--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--*/
/* --== RESPONSIVO =========================================-- */


/* --===-- */
/* --===-- */
/* --== MEDIA 950px ==-- */
@media all and (max-width:950px){
/* --==-- */

/* --== CAROUSEL 01 ==-- */
.carousel-01 {padding:0px 20px;}

/* --== CAROUSEL 02 ==-- */
.carousel-02 {padding:0px 20px;}

/* --== CAROUSEL 03 ==-- */
.carousel-03 {padding:0px 20px;}

/* --== CAROUSEL 04 ==-- */
.carousel-04 {padding:0px 20px;}

/* --== CAROUSEL 05 ==-- */
.carousel-05 {padding:0px 20px;}
	
}

/* --===-- */
/* --===-- */
/* --== MEDIA 650px ==-- */
@media all and (max-width:650px){
/* --==-- */

/* --== CAROUSEL 01 ==-- */	
.bloco-08 .center-content .btn-cta {max-width:100%;}

}

/* ============================================ */
/* CSS By http://nerdweb.com.br */
/* ============================================ */