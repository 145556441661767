/* ============================================ */
/* CSS By http://nerdweb.com.br */
/* ============================================ */

/*--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--*/
/* --== FOOTER =============================================-- */
footer {width:100%;height:auto;float:left;padding:0px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;background-color:#000000;border-top:2px #ffc700 solid;}
footer .center-footer {max-width:1280px;width:100%;height:auto;display:flex;flex-direction:row;flex-wrap:wrap;justify-content:center;align-items:center;}

/* --== LOGO ==-- */
footer .center-footer .logo {max-width:180px;width:100%;height:auto;padding:20px 0px 20px 0px;float:left;display:block;}
footer .center-footer .logo a {width:auto;height:auto;float:left;display:block;}
footer .center-footer .logo img {max-width:150px;width:100%;height:auto;float:left;cursor:pointer;}
footer .center-footer .logo img:hover {transform:scale(1.03);transition:0.3s;}

/* --== BOX INFOS ==-- */
footer .center-footer .box-infos {max-width:calc(100% - 180px);width:100%;min-height:30px;height:auto;float:left;margin:20px 0px;display:block;}
footer .center-footer .box-infos .informacoes {width:95%;height:auto;margin:30px 0px 50px 0px;padding:0px 0px 0px 30px;float:right;display:block;}
footer .center-footer .box-infos .informacoes ul {width:100%;height:auto;float:left;display:flex;flex-wrap:wrap;justify-content:space-between;}
footer .center-footer .box-infos .informacoes ul li {width:calc(33.333% - 20px);height:auto;float:left;display:block;}
footer .center-footer .box-infos .informacoes ul li h4 {width:100%;height:auto;font-size:14px;line-height:20px;color:#ffffff;font-weight:600;float:left;display:block;}
footer .center-footer .box-infos .informacoes ul li p {width:100%;height:auto;font-size:12px;line-height:18px;color:#ffffff;margin-top:5px;float:left;display:block;}
footer .center-footer .box-infos .informacoes ul li p i {font-size:16px;margin-right:5px;}
footer .center-footer .box-infos .informacoes ul li p .font-14 {font-size:13px;margin-right:6px;}
footer .center-footer .box-infos .informacoes ul li p a {color:#ffffff;transition:0.3s;}
footer .center-footer .box-infos .informacoes ul li p a:hover {color:#FFA800;transition:0.3s;}
footer .center-footer .box-infos .informacoes ul li p .cor-yelow {color:#ffffff;}
footer .center-footer .box-infos .informacoes ul li p .cor-yelow:hover {color:#FFA800;}
footer .center-footer .box-infos .informacoes ul li p .btn-chegar {font-size:13px;color:#ffc700;text-decoration:underline;}
footer .center-footer .box-infos .informacoes ul li p .btn-chegar:hover {color:#FFA800;}

footer .center-footer .box-infos .box-links {min-width:500px;width:100%;min-height:30px;height:auto;float:right;display:block;}
footer .center-footer .box-infos .box-links .menu {width:70%;height:auto;float:left;display:block;}
footer .center-footer .box-infos .box-links .menu ul {width:auto;height:auto;float:right;display:flex;flex-direction:row;align-items:center;justify-content:flex-end;}
footer .center-footer .box-infos .box-links .menu ul li {width:auto;height:auto;float:left;margin-right:15px;display:block;}
footer .center-footer .box-infos .box-links .menu ul li a {width:100%;height:auto;font-size:12px;line-height:34px;font-weight:400;color:#FFFFFF;float:left;display:block;}
footer .center-footer .box-infos .box-links .menu ul li a:hover {color:#FFA800;}
footer .center-footer .box-infos .box-links .menu ul li:last-child {margin:0px;}
footer .center-footer .box-infos .box-links .menu ul .btn-msg {border:1px solid #333333;background-image:linear-gradient(45deg, #FFA800 50%, transparent 50%);background-position:100%;background-size:400%;transition:background 250ms ease-in-out;transition:0.3s;}
footer .center-footer .box-infos .box-links .menu ul .btn-msg a {padding:0px 10px;font-weight:500;transition:0.3s;}
footer .center-footer .box-infos .box-links .menu ul .btn-msg:hover {border:1px solid #FFA800;background-position:0;transition:0.3s;}
footer .center-footer .box-infos .box-links .menu ul .btn-msg:hover a {color:#000000;}

footer .center-footer .box-infos .box-links .redes-sociais {width:30%;height:auto;float:right;display:flex;justify-content: flex-end;}
footer .center-footer .box-infos .box-links .redes-sociais ul {width:auto;height:auto;float:left;display:block;}
footer .center-footer .box-infos .box-links .redes-sociais ul li {width:auto;height:auto;float:left;margin-left:15px;display:block;}
footer .center-footer .box-infos .box-links .redes-sociais ul li a {width:100%;height:auto;font-size:26px;line-height:34px;color:#FFFFFF;float:left;display:block;}
footer .center-footer .box-infos .box-links .redes-sociais ul li a:hover {color:#FFA800;transform:scale(1.1);transition:0.3s;}

footer .center-footer .copyright {width:100%;height:auto;padding:20px 0px;float:left;display:block;border-top:1px #202020 solid;}
footer .center-footer .copyright .box-txt {width:100%;height:auto;float:left;display:block;}
footer .center-footer .copyright .box-txt p {width:auto;height:auto;font-size:12px;color:#666666;line-height:22px;text-align:center;margin-bottom:0px;position:relative;}
footer .center-footer .copyright .box-txt p .desenvolvido a {width:auto;height:auto;font-size:12px;color:#666666;font-weight:600;transition:0.2s;}
footer .center-footer .copyright .box-txt p .desenvolvido a:hover {color:#008aff;transition:0.2s;}


/*--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--*/
/*--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--*/
/* --== MEDIA 1050px ========================================-- */
@media all and (max-width:1050px){
footer {padding:0px 20px;}
}

/*--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--*/
/*--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--*/
/* --== MEDIA 1000px ========================================-- */
@media all and (max-width:1000px){
/* --== LOGO ==-- */
footer .center-footer .logo {max-width:100%;padding:60px 0px 20px 0px;display:flex;flex-direction:column;align-items:center;}
/* --== BOX INFOS ==-- */
footer .center-footer .box-infos {max-width:100%;margin:0px 0px 40px 0px;}
footer .center-footer .box-infos .informacoes {width:100%;margin:20px 0px 40px 0px;padding:0px;}
footer .center-footer .box-infos .box-links {min-width:100%;display:flex;flex-direction:row;flex-wrap:wrap;justify-content:center;align-items:center;}
footer .center-footer .box-infos .box-links .menu {width:auto;}	
}

/*--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--*/
/*--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--*/
/* --== MEDIA 750px ========================================-- */
@media all and (max-width:750px){
	
footer .center-footer .box-infos .informacoes {margin:20px 0px 20px 0px;}
footer .center-footer .box-infos .informacoes ul {flex-direction:column;flex-wrap:nowrap;justify-content:center;align-items:center;padding-left:0}
footer .center-footer .box-infos .informacoes ul li {max-width:320px;width:100%;margin:10px 0px 10px 0px;}
footer .center-footer .box-infos .informacoes ul li h4 {text-align:center;}
footer .center-footer .box-infos .informacoes ul li p {text-align:center;}

footer .center-footer .box-infos .box-links {flex-direction:column;flex-wrap:nowrap;}

footer .center-footer .box-infos .box-links .menu {width:70%;}	
footer .center-footer .box-infos .box-links .menu ul {width:100%;flex-direction:column;justify-content:center;}
footer .center-footer .box-infos .box-links .menu ul li {width:100%;margin-right:0px;}
footer .center-footer .box-infos .box-links .menu ul li a {text-align:center;}
footer .center-footer .box-infos .box-links .menu ul li:last-child {margin-top:20px;}

footer .center-footer .box-infos .box-links .redes-sociais {width:30%;margin:30px 0px 0px 0px;display: flex;justify-content: flex-end;}
footer .center-footer .box-infos .box-links .redes-sociais ul {max-width:250px;width:100%;display:flex;flex-direction:row;flex-wrap:wrap;justify-content:space-between;align-items:center;}
footer .center-footer .box-infos .box-links .redes-sociais ul li {margin-left:0px;}
footer .center-footer .box-infos .box-links .redes-sociais ul li a {font-size:30px;line-height:34px;}

footer .center-footer .copyright .box-txt p .desenvolvido {width:100%;float:left;}
	
footer .center-footer .copyright .box-txt p .desenvolvido a {text-align:center;}
}

/*--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--*/
/*--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--*/
/* --== MEDIA 450px ========================================-- */
@media all and (max-width:450px){
footer .center-footer .copyright .box-txt p .direitos {width:100%;text-align:center;float:left;}
}


/* ============================================ */
/* CSS By http://nerdweb.com.br */
/* ============================================ */