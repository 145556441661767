/* =============================================== */
/* CSS Reset By http://nerdweb.com.br */
/* =============================================== */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/*--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--*/
/* --== FIXOS ==============================================-- */
body {font-family:'Poppins', sans-serif !important;}
body,header,footer {min-width:320px;}
html {font-size:100%;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;}
html,body,object,iframe,section,footer,header,article,aside,div,span,p,h1,h2,h3,h4,h5,h6,hgroup,menu,nav,form,label,button,input,textarea,select,dl,dt,dd,ol,ul,li,tbody,tfoot,thead,tr,th,td,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,fieldset,legend,table,caption,canvas,details,figcaption,figure,summary,time,mark,audio,video {margin:0;padding:0;border:0;outline:0;vertical-align:baseline;background:transparent;box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;-ms-box-sizing:border-box;}
nav ul,nav ol,ul,ol,article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {list-style:none;list-style-image:none;list-style-type:none;}
fieldset {border:1px solid #c0c0c0;margin:0 2px;padding:.35em .625em .75em;}
legend {white-space:normal;margin-left:-7px;}
audio,canvas,video {display:inline-block;display:inline;zoom:1;}
audio:not([controls]) {display:none;height:0;}
[hidden] {display:none;}
blockquote,q {quotes:none;}
blockquote:before,blockquote:after,q:before,q:after {content:'';content:none;}
a {cursor:pointer;margin:0;padding:0;font-size:100%;vertical-align:baseline;text-decoration:none;box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;-ms-box-sizing:border-box;}
a:focus {outline:thin dotted;}
a:active,a:hover {outline:0;}
mark {background-color:#ff9;color:#000;font-style:italic;font-weight:bold;}
del {text-decoration:line-through;}
abbr[title],dfn[title] {border-bottom:1px dotted;cursor:help;}
table {border-collapse:collapse;border-spacing:0;}
button,input,select,textarea {vertical-align:baseline;vertical-align:middle;font-family:'Poppins', sans-serif;}
button,input {line-height:normal;}
button,html input[type="button"],input[type="reset"],input[type="submit"] {-webkit-appearance:button;cursor:pointer;overflow:visible;}
button[disabled],input[disabled] {cursor:default;}
input {box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;-ms-box-sizing:border-box;}
input[type="checkbox"],input[type="radio"] {box-sizing:border-box;padding:0;margin:0;height:13px;width:13px;}
input[type="search"] {-webkit-appearance:textfield;}
input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration {-webkit-appearance:none;}
button::-moz-focus-inner,input::-moz-focus-inner {border:0;padding:0;}
textarea {overflow:auto;vertical-align:top;}
table {border-collapse:collapse;border-spacing:0;}
svg:not(:root) {overflow:hidden;}

/* =============================================== */
/* CSS Reset By - http://nerdweb.com.br */
/* =============================================== */