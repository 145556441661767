/* ============================================ */
/* CSS By http://nerdweb.com.br */
/* ============================================ */

/*--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--*/
/* --== HEADER =============================================-- */
header {width:100%;height:auto;float:left;display:flex;flex-direction:column;justify-content:center;align-items:center;position:fixed;top:0px;transition:0.1s;z-index:998;}
.bar-header-none {top:-50px;transition:0.5s;}
.header-bg-full {background:rgba(0, 0, 0, 1);transition:0.1s;}
.header-bg-small {background:rgba(0, 0, 0, 0.8);transition:0.3s;}

header .bar-header {width:100%;height:auto;float:left;padding:0px 40px;display:flex;flex-direction:column;justify-content:center;align-items:center;border-bottom:1px #202020 solid;}

header .bar-header .bar-center-header {max-width:1280px;width:100%;height:50px;display:flex;flex-direction:row;justify-content:flex-end;align-items:center;}

header .bar-header .bar-center-header .menu {width:calc(100% - 15rem);height:auto;float:left;display:block;margin-right:16px;}
header .bar-header .bar-center-header .menu ul {width:auto;height:auto;float:right;display:flex;flex-direction:row;align-items:center;justify-content:flex-end;margin-bottom: 0;}
header .bar-header .bar-center-header .menu ul li {width:auto;height:auto;float:left;margin-right:15px;display:block;}
header .bar-header .bar-center-header .menu ul li a {width:100%;height:auto;font-size:12px;line-height:34px;font-weight:300;color:#FFFFFF;float:left;display:block;}
header .bar-header .bar-center-header .menu ul li a i {font-size:16px;margin-right:3px;}
header .bar-header .bar-center-header .menu ul li a:hover {color:#FFA800;}
header .bar-header .bar-center-header .menu ul li:last-child {margin:0px;}

header .bar-header .bar-center-header .redes-sociais {width:auto;height:auto;float:right;display:block;}
header .bar-header .bar-center-header .redes-sociais ul {width:auto;height:auto;float:left;display:block;margin-bottom: 0;}
header .bar-header .bar-center-header .redes-sociais ul li {width:auto;height:auto;float:left;margin-left:15px;display:block;}
header .bar-header .bar-center-header .redes-sociais ul li a {width:100%;height:auto;font-size:20px;line-height:34px;color:#FFFFFF;float:left;display:block;}
header .bar-header .bar-center-header .redes-sociais ul li a:hover {color:#FFA800;transform:scale(1.1);transition:0.3s;}


header .center-header {max-width:1360px;width:100%;height:auto;padding:10px 40px;display:flex;flex-direction:row;justify-content:center;align-items:center;}

/* --== LOGO ==-- */
header .center-header .logo {width:280px;height:auto;float:left;display:block;position:relative;z-index:100;cursor:pointer;}
header .center-header .logo h1 {width:auto;height:auto;float:left;display:flex;}
header .center-header .logo a {width:auto;height:auto;float:left;display:flex;}
header .center-header .logo img {width:100%;height:auto;padding:10px 10px 10px 0px;}
header .center-header .logo .logo-full {max-width:250px;margin:10px 0px;transition:0.1s;}
header .center-header .logo .logo-small {max-width:220px;margin:4px 0px;transition:0.3s;}

/* --== NAV ==-- */
header .center-header .box-nav {width:100%;height:auto;float:left;display:flex;position:relative;}
header .center-header .box-nav .nav-web {width:100%;height:auto;float:left;display:flex;align-items:center;}
header .center-header .box-nav .nav-web ul {width:100%;height:auto;float:left;display:flex;flex-direction:row;justify-content:flex-end;align-items:center;margin-bottom: 0;}
header .center-header .box-nav .nav-web ul li {width:auto;height:auto;float:left;margin:0px 15px 0px 0px;display:block;}
header .center-header .box-nav .nav-web ul li:nth-last-child(1) {margin-right:0px;}
header .center-header .box-nav .nav-web ul li:first-child {margin-left:0px;}
header .center-header .box-nav .nav-web ul li a {width:auto;height:auto;font-size:15px;color:#FFFFFF;line-height:50px;font-weight:500;cursor:pointer;transition:0.2s;}
header .center-header .box-nav .nav-web ul li a:hover {color:#FFA800;}
header .center-header .box-nav .nav-sites {width:auto;max-width:100%;height:auto;float:right;display:flex;align-items:center;}
header .center-header .box-nav .nav-sites ul {width:auto;height:auto;float:left;display:block;margin-bottom: 0;}
header .center-header .box-nav .nav-sites ul li {width:auto;height:auto;float:left;margin:0px 20px;display:block;border:1px solid #ffc700;background-image:linear-gradient(45deg, #FFA800 50%, transparent 50%);background-position:100%;background-size:400%;transition:background 250ms ease-in-out;transition:0.3s;}
header .center-header .box-nav .nav-sites ul li:hover {border:1px solid #FFA800;}
header .center-header .box-nav .nav-sites ul li:nth-last-child(1) {margin-right:0px;}
header .center-header .box-nav .nav-sites ul li a {width:200px;height:auto;font-size:13px;color:#FFFFFF;line-height:40px;font-weight:500;padding:0px 25px;cursor:pointer;transition:0.2s;display: flex;justify-content: center;}
header .center-header .box-nav .nav-sites ul li:hover {border:1px solid #FFA800;background-position:0;transition:0.3s;}
header .center-header .box-nav .nav-sites ul li:hover a {color:#000000;}
header .center-header .box-nav .container {width:auto;height:auto;}
header .center-header .box-nav .menu-mobile {width:auto;height:auto;font-size:30px;line-height:30px;color:#FFFFFF;float:right;display:none;cursor:pointer;}
header .center-header .box-nav .menu-mobile:hover {color:#FFA800;}

/* MENU MOBILE Overlay style */
header .overlay {width:100%;height:100%;top:0;left:0;position:fixed;z-index:999;}

/* MENU MOBILE Overlay closing cross */
header .overlay .overlay-close {width:40px;height:40px;position:absolute;right:20px;top:20px;overflow:hidden;border:none;background:url(../img/cross.png) no-repeat center center;text-indent:200%;color:transparent;outline:none;z-index:100;}
header .overlay .overlay-close:hover {background:url(../hotsite/cross-hover.png) no-repeat center center;}

/* MENU MOBILE Menu style */
header .overlay nav {text-align:center;position:relative;top:50%;height:auto;-webkit-transform:translateY(-50%);transform:translateY(-50%);}
header .overlay ul {height:calc(100vh - 150px);list-style:none;padding:0;margin:0 auto;display:flex;flex-direction:column;align-items:center;justify-content:space-around;position:relative;}
header .overlay ul li {display:block;height:auto;height:calc(100% / 11);min-height:40px;-webkit-backface-visibility:hidden;backface-visibility:hidden;}
header .overlay ul li a {font-size:18px;font-weight:300;display:block;color:#fff;-webkit-transition:color 0.2s;transition:color 0.2s;}
header .overlay ul li a:hover,
header .overlay ul li a:focus {color:#FFA800;}

header .overlay ul .cta-menu {min-width:250px;width:auto;height:auto;float:left;margin:0px 20px;display:block;border:1px solid #ffc700;background-image:linear-gradient(45deg, #FFA800 50%, transparent 50%);background-position:100%;background-size:400%;transition:background 250ms ease-in-out;transition:0.3s;}
header .overlay ul .cta-menu:hover {border:1px solid #FFA800;}
header .overlay ul .cta-menu:nth-last-child(1) {margin-right:0px;}
header .overlay ul .cta-menu a {width:auto;height:auto;font-size:13px;color:#FFFFFF;line-height:40px;font-weight:500;padding:0px 25px;cursor:pointer;transition:0.2s;}
header .overlay ul .cta-menu:hover {border:1px solid #FFA800;background-position:0;transition:0.3s;}
header .overlay ul .cta-menu:hover a {color:#000000;}

/* MENU MOBILE Effects */
header .overlay-contentpush {background:rgba(0, 0, 0, 0.9);visibility:hidden;-webkit-backface-visibility:hidden;backface-visibility:hidden;-webkit-transform:translateX(-100%);transform:translateX(-100%);-webkit-transition:-webkit-transform 0.5s,visibility 0s 0.5s;transition:transform 0.5s,visibility 0s 0.5s;}

header .overlay-contentpush.open {visibility:visible;-webkit-transform:translateX(0%);transform: translateX(0%);-webkit-transition:-webkit-transform 0.5s;transition:transform 0.5s;}


/*--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--*/
/* --== MEDIA 1050px ========================================-- */
@media all and (max-width:1050px){
/* --== MENU ==-- */
.bar-header-none {top:0px;transition:0.3s;}
header .center-header {padding:0px 20px;}
header .bar-header {display:none;}
header .center-header {justify-content:space-between;}
header .center-header .box-nav {width:calc(100% - 185px);display:block;align-items:flex-end;}
header .center-header .box-nav .menu-mobile {display:flex;}
header .center-header .box-nav .nav-web {display:none;}
header .center-header .box-nav .nav-sites {display:none;}
}

/*--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--*/
/* --== MEDIA - MENU MOBILE HEIGHT =========================-- */
@media screen and (max-height:30.5em) {
header .overlay nav {height:70%;font-size:34px;}

}


/* ============================================ */
/* CSS By http://nerdweb.com.br */
/* ============================================ */